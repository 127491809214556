<template>
  <el-card>
    <el-form class="" label-width="120px" label-position="right">
      <el-form-item label="预约项目名称：" prop="itemName">
        {{ formData.itemName }}
      </el-form-item>
      <el-form-item label="所属公园：" prop="parkName">
        {{ formData.parkName }}
      </el-form-item>

      <div class="table">
        <el-table class="global-table mb20 dlg-table"
          max-height="400"
          :data="formData.appointItemTimeList"
          ref="table">
          <el-table-column label="预约入园时间段" prop="workerendTimeIdList" align='center' showOverflowTooltip>
            <template v-slot="{ row }">
              <div>{{row.startTime}} - {{row.endTime}}</div>
            </template>
          </el-table-column>
          <el-table-column label="预约人数上限" prop="limitNum" align='center' showOverflowTooltip></el-table-column>
        </el-table>
      </div>

      <el-form-item label="开启状态：" prop="itemStatus">
        {{ formData.itemStatus === '1' ? '已开启' : '已关闭' }}
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: 'AppointProjectDetail',
  data () {
    return {
      formData: {
        parkId: '',
        itemName: '',
        appointItemTimeList: []
      }
    }
  },
  computed: {
    itemId () {
      return this.$route.params.id
    }
  },
  created () {
    if (this.itemId) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.appointManage.selectAppointItemByItemId, {
        itemId: this.itemId
      }).then(res => {
        this.formData = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
